<template>
  <div class="card card-custom">
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label">{{ $t('user_details.add_external_balance') }}</h3>
      </div>
      <div class="card-toolbar">
        <!--begin::Button-->
        <router-link 
          :to="`/users/${$route.params.id}/external-balance`"
          class="btn btn-sm btn-secondary text-primary font-weight-bold"
        >
          <i class="flaticon2-back text-primary fa-1x"></i> {{ $t("commons.back") }}
        </router-link>
        <!--end::Button-->
      </div>
    </div>
    <div class="card-body">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form class="form">
          <div data-wizard-type="step-content" data-wizard-state="current">
            <div class="row">
              <div class="col-xl-12">
                <div class="form-group">
                  <label class="col-form-label">{{ $t("user_details.provider") }}</label>
                  <validation-provider
                    rules="required"
                    :name="$t('user_details.provider')"
                    v-slot="{ classes, errors }"
                    v-if="!isLoading"
                  >
                    <input
                      :class="classes"
                      type="text"
                      class="form-control"
                      v-model="form.provider"
                    />
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                  <div v-else>
                    <Skeleton height="38px" />
                  </div>
                </div>

                <div class="form-group">
                  <label class="col-form-label">{{ $t("commons.currency") }}</label>
                  <validation-provider
                    rules="required"
                    :name="$t('commons.currency')"
                    v-slot="{ classes, errors }"
                    v-if="!isLoading"
                  >
                    <multiselect
                      v-model="selectedCurrency"
                      :options="currencies"
                      :searchable="false"
                      :allow-empty="false"
                      open-direction="bottom"
                      deselect-label=""
                      select-label=""
                      selected-label=""
                      track-by="name"
                      label="name"
                      :placeholder="$t('commons.currency')"
                      :class="classes"
                      @input="onChangeCurrency"
                    >
                      <template
                        slot="singleLabel"
                        slot-scope="{ option }"
                        >{{ option.name }}</template
                      >
                      <template slot="option" slot-scope="{ option }">{{
                        option.name
                      }}</template>
                      <template slot="noOptions">{{
                        "List is empty"
                      }}</template>
                    </multiselect>
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                  <div v-else>
                    <Skeleton height="38px" />
                  </div>
                </div>

                <div class="form-group">
                  <label class="col-form-label">{{ $t("commons.quantity") }}</label>
                  <validation-provider
                    rules="required"
                    :name="$t('commons.quantity')"
                    v-slot="{ classes, errors }"
                    v-if="!isLoading"
                  >
                    <currency-input
                      class="form-control"
                      :placeholder="$t('commons.quantity')"
                      :class="classes"
                      :precision="8"
                      v-model="form.quantity"
                    />
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                  <div v-else>
                    <Skeleton height="38px" />
                  </div>
                </div>

                <div class="form-group">
                  <label class="col-form-label">{{ $t('user_details.exchange_rate') }} TRY</label>
                  <validation-provider
                    rules="required|double"
                    :name="`${$t('user_details.exchange_rate')} TRY`"
                    v-slot="{ classes, errors }"
                    v-if="!isLoading"
                  >
                    <input
                      :class="classes"
                      type="number"
                      placeholder="0.00"
                      step="0.01"
                      class="form-control"
                      v-model="form.exchange_rate_try"
                    />
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                  <div v-else>
                    <Skeleton height="38px" />
                  </div>
                </div>

                <div class="form-group">
                  <label class="col-form-label">{{ $t('user_details.exchange_rate') }} USD</label>
                  <validation-provider
                    rules="required|double"
                    :name="`${$t('user_details.exchange_rate')} USD`"
                    v-slot="{ classes, errors }"
                    v-if="!isLoading"
                  >
                    <input
                      :class="classes"
                      type="number"
                      placeholder="0.00"
                      step="0.01"
                      class="form-control"
                      v-model="form.exchange_rate_usd"
                    />
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                  <div v-else>
                    <Skeleton height="38px" />
                  </div>
                </div>

                <div class="form-group">
                  <label class="col-form-label">{{ $t('commons.amount') }} TRY</label>
                  <validation-provider
                    rules="required"
                    :name="`${$t('commons.amount')} TRY`"
                    v-slot="{ classes, errors }"
                    v-if="!isLoading"
                  >
                    <currency-input
                      class="form-control"
                      placeholder="0.000"
                      :class="classes"
                      :precision="2"
                      v-model="form.amount_try"
                    />
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                  <div v-else>
                    <Skeleton height="38px" />
                  </div>
                </div>

                <div class="form-group">
                  <label class="col-form-label">{{ $t('commons.amount') }} USD</label>
                  <validation-provider
                    rules="required"
                    :name="`${$t('commons.amount')} USD`"
                    v-slot="{ classes, errors }"
                    v-if="!isLoading"
                  >
                    <currency-input
                      class="form-control"
                      placeholder="0.000"
                      :class="classes"
                      :precision="2"
                      v-model="form.amount_usd"
                    />
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                  <div v-else>
                    <Skeleton height="38px" />
                  </div>
                </div>

                <div class="form-group">
                  <label class="col-form-label">{{ $t('finance.fee') }} TRY</label>
                  <validation-provider
                    rules="required|double"
                    :name="`${$t('finance.fee')} TRY`"
                    v-slot="{ classes, errors }"
                    v-if="!isLoading"
                  >
                    <input
                      :class="classes"
                      type="number"
                      placeholder="0.00"
                      step="0.01"
                      class="form-control"
                      v-model="form.fee_try"
                    />
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                  <div v-else>
                    <Skeleton height="38px" />
                  </div>
                </div>

                <div class="form-group">
                  <label class="col-form-label">{{ $t('finance.fee') }} USD</label>
                  <validation-provider
                    rules="required|double"
                    :name="`${$t('finance.fee')} USD`"
                    v-slot="{ classes, errors }"
                    v-if="!isLoading"
                  >
                    <input
                      :class="classes"
                      type="number"
                      placeholder="0.00"
                      step="0.01"
                      class="form-control"
                      v-model="form.fee_usd"
                    />
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                  <div v-else>
                    <Skeleton height="38px" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <button
              type="button"
              class="btn btn-primary font-weight-bold w-25"
              @click.prevent="handleSubmit(_handleSubmit)"
              :disabled="isSubmitting"
            >
                {{ $t("commons.save") }}
            </button>
          </div>
        </form>
      </ValidationObserver>

    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import { Skeleton } from 'vue-loading-skeleton';

export default {
  name: "add_external_balance",
  props: {},
  components: {
    Multiselect,
    Skeleton
  },
  computed: {
    ...mapState({
      isLoading: state => state.userdetails.isLoading,
      currencies: state => state.userdetails.externalBalanceFormData.currencies,
      isSubmitting: state => state.userdetails.isSubmitting,
      permissions: (state) => state.customer.permissions,
    }),
  },
  data() {
    return {
      selectedCurrency: null,
      form: {
        provider: "",
        currency_id: null,
        quantity: null,
        exchange_rate_try: null,
        exchange_rate_usd: null,
        amount_try: null,
        amount_usd: null,
        fee_try: null,
        fee_usd: null,
      },
    };
  },
  methods: {
    checkPermission(permission) {
      return this.permissions?.some(val => String(val.name) === String(permission));
    },
    _handleSubmit() {
      this.$store.dispatch('userdetails/externalBalancesStoreAction', {
        id: this.$route.params.id,
        formData: this.form
      })
        .then(() => {
          this.$router.push({ path: `/users/${this.$route.params.id}/external-balance` })
        })
        .catch(() => {})
    },
    onChangeCurrency() {
      this.form.currency_id = this.selectedCurrency.id;
    },
  },
  created() {
    if(this.permissions?.length > 0 && !this.checkPermission('crm.users.show.externalBalances.create')) {
      this.$router.push('/no-permission')
    }
    this.$store.dispatch('userdetails/externalBalancesCreateAction', this.$route.params.id)
  },
};
</script>
<style scoped>
.show {
  display: block;
}
</style>
